import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'

const Liens = props => (
  <Layout>
    <Helmet>
      <title>Liens - Palette Sartoise</title>
      <meta name="description" content="Page de liens de la Palette Sartoise" />
    </Helmet>

    <div id="main" className="wrapper style1">
      <div className="container">
        <header className="major">
          <h2>Liens</h2>
          <p>
            Des liens en rapport avec le tennis de table, le village de Sart-Bernard, la province de Namur ou nos sponsors.
          </p>
        </header>

        <section>
          <h3>Tennis de Table</h3>
          <div className="row">
            <div className="col-6 col-12-xsmall">
              <ul className="alt">
                <li><a href="https://aftt.be/" target="_blank">Aile Francophone de Tennis de Table</a></li>
                <li><a href="https://frbtt-namur.be/" target="_blank">FRBTT Namur</a></li>
                <li><a href="https://www.frbtt.be/" target="_blank">FRBTT</a></li>
                <li><a href="https://resultats.aftt.be/?menu=5&elo=1&cur_page=165" target="_blank">AFTT Classement ELO</a></li>
              </ul>
            </div>
           </div>
          <h3>Sart-Bernard - Assesse</h3>
          <div className="row">
            <div className="col-6 col-12-xsmall">
              <ul className="alt">
                <li><a href="https://www.assesse.be/" target="_blank">Assesse</a></li>
                <li><a href="http://vof-sartbernard.be/site/?page_id=19" target="_blank">VOF - Histoire de Sart-Bernard</a></li>
                <li><a href="https://fr.wikipedia.org/wiki/Sart-Bernard" target="_blank">Wikipedia</a></li>
              </ul>
            </div>
           </div>
        </section>
       </div>
     </div>
  </Layout>
)

export default Liens
